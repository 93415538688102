@import 'layout-default-legacy.css';

.viewport {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.page {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--ui-brandingbar-height));
}

/* START: TODO GUI-3023 create new component ui-toggle-buttonbar */
.page-header__environment {
    margin-top: 0;
    height: 100%;
    display: flex;
    flex: 1 1 var(--ui-227px);
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    box-sizing: border-box;
}

.page-header__environment > li {
    display: inline-block;
}

.page-header__environment .page-header__environment-link {
    --ui-focus-offset: var(--ui-focus-offset-negative);
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    line-height: 22px;
    color: var(--ui-color-bark-70);
    white-space: nowrap;
}

.page-header__environment .page-header__environment-link:not(:disabled):hover {
    text-decoration: underline;
}

.page-header__environment .page-header__environment-link:disabled {
    padding-top: 3px;
    border-bottom: 3px solid var(--ui-color-orange);
    font-weight: var(--ui-font-weight-bold);
    opacity: 1;
}

@media screen and (max-width: 1023.98px) {
    .page-header__environment .page-header__environment-link {
        padding: 0 10px;
    }
}

/* END: TODO GUI-3023 create new component ui-toggle-buttonbar */

@media screen and (max-width: 1023.98px) {
    ui-navitem[type='custom'].ui-navitem__lang {
        order: 1;
        height: 50px;
        border-bottom: 1px solid var(--ui-color-grey);
    }

    html[class$='-expanded'],
    .-nav-main-expanded,
    .-navtoggle-expanded {
        position: fixed;
        width: 100%;
        height: 100%;
    }
}

ui-layout-head {
    margin: 32px 0 8px;
    padding-left: 20px;
}

@media screen and (max-width: 1023px) {
    ui-layout-head {
        margin-left: 8px;
        padding: 0;
    }
}
